import React, { Component } from "react";
import { Components as PCFComponents } from "@platformcommon/platform-common-frontend";
import { appProps } from "./utils/navMenuUtils";
import "./overrides.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const appDetails = { ...appProps, title: "Organisation Source Licences" };

    return (
      <PCFComponents.NCSCAppWrapper {...appDetails}>
        <PCFComponents.PageContent>
          <div>
            <div className="mb-3">
              <b>
                NCSC consumes the following datasets to produce our organisation
                search results:
              </b>
            </div>

            <ul>
              <li className="mb-3">
                <div>
                  <a
                    href="https://www.gov.uk/government/organisations/companies-house"
                    rel="nofollow"
                  >
                    <strong>Companies House</strong>
                  </a>
                </div>
                <div>
                  Companies House is the United Kingdom's registrar of companies
                  and is an executive agency and trading fund of Her Majesty's
                  Government. All forms of companies (as permitted by the United
                  Kingdom Companies Act) are incorporated and registered with
                  Companies House and file specific details as required by the
                  current Companies Act 2006. All registered limited companies,
                  including subsidiary, small and inactive companies, must file
                  annual financial statements in addition to annual company
                  returns, which are all public records.
                  <div>
                    <a
                      href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                      rel="nofollow"
                    >
                      Open Government Licence
                    </a>
                  </div>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a
                    href="https://get-information-schools.service.gov.uk/Downloads"
                    rel="nofollow"
                  >
                    <strong>Education England and Wales Establishments</strong>
                  </a>
                </div>
                <div>
                  Data sourced from the Get Information About Schools (GIAS)
                  service operated by the Government Digital Service
                  <div>
                    <a
                      href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                      rel="nofollow"
                    >
                      Open Government Licence
                    </a>
                  </div>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a
                    href="http://apps.education-ni.gov.uk/appinstitutes/default.aspx"
                    rel="nofollow"
                  >
                    <strong>Education Northern Ireland Establishments</strong>
                  </a>
                </div>
                <div>
                  Data sourced from the Northern Ireland Government Department
                  of Education website
                  <div>
                    <a
                      href="https://www.education-ni.gov.uk/crown-copyright"
                      rel="nofollow"
                    >
                      Crown Copyright
                    </a>
                  </div>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a
                    href="https://www.gov.scot/publications/school-contact-details/"
                    rel="nofollow"
                  >
                    <strong>Education Scotland Establishments</strong>
                  </a>
                </div>
                <div>
                  Data sourced from the Scottish Government website schools
                  contact details list
                  <div>
                    <a
                      href="https://www.gov.scot/crown-copyright/"
                      rel="nofollow"
                    >
                      Crown Copyright
                    </a>
                  </div>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a
                    href="http://search.electoralcommission.org.uk/Search/Registrations"
                    rel="nofollow"
                  >
                    <strong>Electoral Commission Registrations</strong>
                  </a>
                </div>
                <div>
                  The Electoral Commission is the independent body which
                  oversees elections and regulates political finance in the UK.
                  They maintain registers of Political Parties, Non-Party
                  Campaigners and Referendum Participants
                  <div>
                    <a
                      href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                      rel="nofollow"
                    >
                      Open Government Licence
                    </a>
                  </div>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a href="https://www.gov.uk/api/organisations" rel="nofollow">
                    <strong>Gov.uk</strong>
                  </a>
                </div>
                <div>
                  GOV.UK Content API makes it easy to access the data used to
                  render content on https://www.gov.uk
                </div>
                <div>
                  <a
                    href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                    rel="nofollow"
                  >
                    Open Government Licence
                  </a>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a
                    href="https://www.registers.service.gov.uk/registers/government-domain"
                    rel="nofollow"
                  >
                    <strong>Government Domains</strong>
                  </a>
                </div>
                <div>
                  Government register of government domains that appear on
                  GOV.UK
                </div>
                <div>
                  <a
                    href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                    rel="nofollow"
                  >
                    Open Government Licence
                  </a>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a
                    href="https://www.gov.uk/government/organisations"
                    rel="nofollow"
                  >
                    <strong>Government Organisation List</strong>
                  </a>
                </div>
                <div>
                  Government web page with a list of government organisations
                  broken down by type
                </div>
                <div>
                  <a
                    href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                    rel="nofollow"
                  >
                    Open Government Licence
                  </a>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a href="https://data.police.uk/docs/" rel="nofollow">
                    <strong>Police</strong>
                  </a>
                </div>
                <div>
                  data.police.uk is the site for open data about crime and
                  policing in England, Wales and Northern Ireland. It contains
                  detailed crime data and information about individual police
                  forces and neighbourhood teams.
                </div>
                <div>
                  <a
                    href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                    rel="nofollow"
                  >
                    Open Government Licence
                  </a>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a
                    href="https://isd.digital.nhs.uk/trud3/user/guest/group/0/home "
                    rel="nofollow"
                  >
                    <strong>NHS England</strong>
                  </a>
                </div>
                <div>
                  Technology Reference Data Update Distribution (TRUD) provides
                  a mechanism for NHS Digital to license and distribute
                  reference data to interested parties. This data is sourced
                  from the "ODS XML Organisation Data" item
                </div>
                <div>
                  <a
                    href="https://isd.digital.nhs.uk/trud3/user/guest/group/0/pack/5/subpack/341/licences"
                    rel="nofollow"
                  >
                    Licences
                  </a>
                </div>
              </li>
              <li className="mb-3">
                <div>
                  <a
                    href="https://statistics.gov.scot/resource?uri=http%3A%2F%2Fstatistics.gov.scot%2Fdata%2Feducation-establishments"
                    rel="nofollow"
                  >
                    <strong>
                      Scotland Statistics Education Establishments
                    </strong>
                  </a>
                </div>
                <div>
                  Data sourced from statistics.gov.scot which provides a range
                  of official statistics about Scotland from a variety of data
                  producers, for information and re-use. It provides reference
                  information on schools and other types of educational
                  establishments.
                </div>
                <div>
                  <a
                    href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                    rel="nofollow"
                  >
                    Open Government Licence
                  </a>
                </div>
              </li>
              <li className="mb-3">
                <div>
                  <a
                    href="https://www.gov.uk/check-university-award-degree/recognised-bodies"
                    rel="nofollow"
                  >
                    <strong>Recognised Bodies</strong>
                  </a>
                </div>
                <div>
                  Data sourced from a government web page with a list of
                  recognised university bodies.
                </div>
                <div>
                  <a
                    href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                    rel="nofollow"
                  >
                    Open Government Licence
                  </a>
                </div>
              </li>
              <li className="mb-3">
                <div>
                  <a
                    href="https://www.charitycommissionni.org.uk/"
                    rel="nofollow"
                  >
                    <strong>The Charity Commission for Northern Ireland</strong>
                  </a>
                </div>
                <div>
                  Data sourced from the Charity Commission for Northen Ireland.
                </div>
                <div>
                  <a
                    href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                    rel="nofollow"
                  >
                    Open Government Licence
                  </a>
                </div>
              </li>
              <li className="mb-3">
                <div>
                  <a
                    href="https://www.oscr.org.uk/about-charities/search-the-register/charity-register-download/"
                    rel="nofollow"
                  >
                    <strong>Office of the Scottish Charity Regulator</strong>
                  </a>
                </div>
                <div>
                  &copy; Crown Copyright and database right 2021. Contains
                  information from the Scottish Charity Register supplied by the
                  Office of the Scottish Charity Regulator and licensed under
                  the Open Government Licence v.3.0.
                </div>
                <div>
                  <a
                    href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                    rel="nofollow"
                  >
                    Open Government Licence v.3.0
                  </a>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a
                    href="https://register-of-charities.charitycommission.gov.uk/register/full-register-download"
                    rel="nofollow"
                  >
                    <strong>Charity Commission for England and Wales</strong>
                  </a>
                </div>
                <div>Data sourced from the Charity Commission.</div>
                <div>
                  <a
                    href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                    rel="nofollow"
                  >
                    Open Government Licence v.3.0
                  </a>
                </div>
              </li>

              <li className="mb-3">
                <div>
                  <a
                    href="https://www.gov.scot/publications/national-public-bodies-directory/pages/introduction/"
                    rel="nofollow"
                  >
                    <strong>Scottish Government</strong>
                  </a>
                </div>
                <div>Data sourced from the Scottish Government.</div>
                <div>
                  <a
                    href="https://www.gov.scot/crown-copyright/"
                    rel="nofollow"
                  >
                    Licence
                  </a>
                </div>
              </li>
              <li className="mb-3">
                <div>
                  <a
                    href="http://www.guernseyregistry.com/CharitiesRegister"
                    rel="nofollow"
                  >
                    <strong>The Guernsey Register</strong>
                  </a>
                </div>
                <div>
                  Data sourced from the Charity Register from the Guernsey
                  Registry.
                </div>
                <div>
                  <a
                    href="http://www.guernseyregistry.com/article/2946/Disclaimer"
                    rel="nofollow"
                  >
                    Copyright 2011 Guernsey Registry
                  </a>
                </div>
              </li>
              <li className="mb-3">
                <div>
                  <a
                    href="https://gov.wales/register-devolved-public-bodies"
                    rel="nofollow"
                  >
                    <strong>
                      The Welsh Government register of devolved public bodies
                    </strong>
                  </a>
                </div>
                <div>
                  Data sourced from the Welsh Government register of devolved
                  public bodies.
                </div>
                <div>
                  <a
                    href="https://gov.wales/copyright-statement"
                    rel="nofollow"
                  >
                    Open Government Licence v.3.0
                  </a>
                </div>
              </li>
              <li className="mb-3">
                <div>
                  <a
                    href="https://www.justiceinspectorates.gov.uk/hmicfrs/fire-and-rescue-services/fire-rescue-services-fire/"
                    rel="nofollow"
                  >
                    <strong>
                      Her Majesty’s Inspectorate of Constabulary and Fire &
                      Rescue Services
                    </strong>
                  </a>
                </div>
                <div>
                  Data sourced from Her Majesty’s Inspectorate of Constabulary
                  and Fire & Rescue Services.
                </div>
                <div>
                  <a
                    href="https://www.justiceinspectorates.gov.uk/hmicfrs/terms-and-conditions/"
                    rel="nofollow"
                  >
                    Open Government Licence v.3.0
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </PCFComponents.PageContent>
      </PCFComponents.NCSCAppWrapper>
    );
  }
}

export default App;
