export let appProps = {
  title: "Reporting a cyber security incident",
  noAuth: true,
  cookieMessage: {
    show: true,
    cookieDomain:
      document.domain === "report.ncsc.gov.uk"
        ? "ncsc.gov.uk"
        : document.domain,
  },
  footerMenus: [
    {
      header: "Quick Links",
      links: [
        {
          title: "Contact Us",
          to:
            "mailto:incidents@ncsc.gov.uk?subject=Contact%20us:%20Reporting%20a%20cyber%20security%20incident",
        },
        {
          title: "Terms and Conditions",
          to: "https://acdhub.service.ncsc.gov.uk/terms-and-conditions",
        },
      ],
    },
  ],
};
